import React from "react";
import './search-pill.scss';
import { PillContent } from "../../shared/models/app-data-model";
import { navigate } from "gatsby";
import useFilteredQueryParams from "../../shared/hooks/filter-params/useFilteredQueryParams";

export type SearchPillContent = {
    content: PillContent;
}

const SearchPill: React.FC<SearchPillContent> = ({ content }: SearchPillContent) => {
    let queryParams = new URLSearchParams();
    const isBrowser = typeof window !== "undefined";
    /* istanbul ignore else */
    if (isBrowser) {
        queryParams = new URLSearchParams(window?.location.search);
    }

    const retainTabLocation = useFilteredQueryParams(queryParams, ['tab'], {query: content?.pill?.display_text});
    const retainTabLocationAddFilter = useFilteredQueryParams(queryParams, ['tab'], {query: content?.pill?.display_text, or_filter: content?.pill?.search_term});
    const callSearch = () => navigate(`/search/?${content?.pill?.search_term ? retainTabLocationAddFilter : retainTabLocation}`);

    return (
        <button className="search-pill unstyled-btn" onClick={() => callSearch()} data-testid="search-pill">
            {content?.pill?.display_text}
        </button>
    );
}

    export default SearchPill;