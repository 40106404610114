import React, { useContext, useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";

import { corewellHeroOverrideClasses, layout } from "../../constants/constants";
import { Media } from "../../shared/contexts/media/MediaContext";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import { IconPack, SearchBoxContent } from "../../shared/models/app-data-model";
import CorewellSearchBar from "../corewell-search-bar/corewell-search-bar";
import "./search-box.scss";

export type SearchBoxProps = {
  content: SearchBoxContent;
  iconPack: IconPack[];
  queryText: string;
  subsiteNavPresent?: boolean;
  headerPresent?: boolean;
  corewellStyleOverrides?: boolean;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  content,
  iconPack,
  queryText,
  subsiteNavPresent,
  headerPresent,
  corewellStyleOverrides
}: SearchBoxProps) => {
  const uiStateContext = useContext(UiStateContext);
  const noMarginTopClass = !headerPresent ? "no-margin-top" : "";

  const marginTopOverride = corewellStyleOverrides ? `${corewellHeroOverrideClasses?.heroMarginTopOverride}` : "";
  const searchBoxContainerClasses = `search-box-container ${noMarginTopClass} ${marginTopOverride}`;

  return (
    <div className={searchBoxContainerClasses} id="search-box-container" data-testid="search-box-container">
      <Flex
        className="search-box-content"
        width={layout.pageWidth}
        px={layout.pagePaddingX}
        marginX={"auto"}
        flexDirection={"column"}
        data-testid="search-box-content"
      >
        {content?.search_hero?.search_hero_title && content?.search_hero?.search_hero_description && (
          <div className="search-box-text-container">
            <h1 data-testid="search-box-header" className="search-box-header">
              {content.search_hero.search_hero_title}
            </h1>
            <span data-testid="search-box-description" className="search-box-description">
              {content.search_hero.search_hero_description}
            </span>
          </div>
        )}
        <CorewellSearchBar content={content?.search_bar?.[0]} iconPack={iconPack} queryText={queryText} />
      </Flex>
    </div>
  );
};

export default SearchBox;