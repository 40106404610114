import { Dispatch, useCallback, useEffect, useState } from 'react';
import KyruusApiService, { KyruusApiResponse, KyruusApiProps } from '../../services/kyruus-api/kyruus-api-service';

export type KyruusApiStatus = {
  response: Array<KyruusApiResponse>;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};


const useKyruusApi = (kyruusProps: KyruusApiProps): [KyruusApiStatus, Dispatch<React.SetStateAction<KyruusApiProps>>] => {
  const [kyruusQuery, setKyruusQuery] = useState(kyruusProps);
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState(new Array<KyruusApiResponse>());
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as KyruusApiStatus);

  useEffect(() => {
    setApiStatus({
      isRunning: isRunning,
      hasError: hasError,
      response: response,
      error: error
    });

  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    setIsRunning(true);
    setHasError(false);

    if (kyruusQuery?.unified) {
      try {
        const response = await KyruusApiService.getKyruusResults(kyruusQuery);
        setIsRunning(false);
        setResponse(response);
      } catch (err) {
        setError(err);
        setHasError(true);
        setIsRunning(false);
      }
    } else {
      setIsRunning(false);
      return;
    }
  }, [
        kyruusQuery?.unified, 
        kyruusQuery?.page, 
        kyruusQuery?.per_page, 
        kyruusQuery?.origin, 
        kyruusQuery?.distance, 
        kyruusQuery?.filter, 
        kyruusQuery?.orFilter,
        kyruusQuery?.notFilter
    ]);

  useEffect(() => {
    apiCall();
}, [apiCall]);

  return [apiStatus, setKyruusQuery];
};

export default useKyruusApi;
