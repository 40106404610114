import React, { useContext, useEffect, useRef, useState } from "react";

//import { Helmet } from "react-helmet";
import GlobalFlatFooter from "../../global-flat-footer/global-flat-footer";
import GlobalFooter from "../../global-footer/global-footer";
import EmbeddedScript from "../../shared/components/embedded-script/embedded-script";
import SEO from "../../shared/components/seo/seo";
import StickyHeader from "../../sticky-header/sticky-header";
import { AppDataContext } from "../../shared/contexts/app-data/AppDataContext";
import { UiStateContext } from "../../shared/contexts/ui-state/UiStateContext";
import usePageHasScrolled from "../../shared/hooks/page-has-scrolled/usePageHasScrolled";
import { DeviceType } from "../../shared/hooks/use-breakpoint/device-type";
import useBreakpoint from "../../shared/hooks/use-breakpoint/useBreakpoint";
import useFeatureToggle from "../../shared/hooks/use-feature-toggle/useFeatureToggle";
import SearchBox from "../../search/search-box/search-box";
import {
  GlobalAlertsContent,
  GlobalNavContent,
  GlobalSettings,
  PrimaryHeaderContent,
  GlobalFooterContent,
  IconPack,
  GlobalToolbarContent,
  RegionalLinksModalContent,
  GlobalFlatFooterContent,
  SearchFilterContent
} from "../../shared/models/app-data-model";
import { SearchPageContent } from "./search-page-model";
import SearchTab from '../../search/search-tab/search-tab';
import "./search-page.scss";
import { Box } from "rebass/styled-components";
import { layout } from "../../constants/constants";
import DynamicSearchResults from "../../search/dynamic-search-results/dynamic-search-results";
import useFilteredQueryParams from "../../shared/hooks/filter-params/useFilteredQueryParams";
import { navigate } from "gatsby";
import { useLocation } from "@gatsbyjs/reach-router";

export type SearchPageQueryResponse = {
  shSearchPage: SearchPageContent;
  shSpectrumhealthOrgPrimaryHeader: PrimaryHeaderContent;
  shGlobalHeaderNav: GlobalNavContent; // Global Nav for corewellhealth.org
  shSpectrumHealthOrgGlobalNav: GlobalNavContent; // Global Nav for spectrumhealth.org
  shGlobalAlertsSpectrumhealthOrg: GlobalAlertsContent;
  shSpectrumhealthOrgGlobalSettings: GlobalSettings;
  shSpectrumhealthOrgGlobalFooterNav?: GlobalFooterContent;
  shGlobalToolbar: GlobalToolbarContent; //Global Toolbar for corewellhealth.org
  shGlobalFlatFooter?: GlobalFlatFooterContent;
  shRegionalLinksModal?: RegionalLinksModalContent;
};

export type SearchPageProps = {
  data: SearchPageQueryResponse;
  corewellStyleOverrides?: boolean;
  clearStepWidget?: string;
  clearStepResizeScript?: string;
};

/**
 * This renders our actual global search page!
 * If you need to update the query, please check pages/index.tsx
 */
const GlobalSearchPage: React.FC<SearchPageProps> = (props: SearchPageProps) => {
  const globalNavContent: GlobalNavContent = props.data.shGlobalHeaderNav ?? props.data.shSpectrumHealthOrgGlobalNav;
  const filterContent: SearchFilterContent = props.data.shSearchPage?.location_tab[0]?.filter_content;
  const appDataContext = useContext(AppDataContext);
  const uiStateContext = useContext(UiStateContext);
  const [menusOpen, setMenusOpen] = useState(uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen);
  const pageRef = useRef<HTMLDivElement>(null);
  const staticIcons = props.data?.shSpectrumhealthOrgGlobalSettings.site_theme.find((x) => x.icon_pack)?.icon_pack as IconPack[];
  const siteName = props.data.shSpectrumhealthOrgGlobalSettings.title;
  const deviceType = useBreakpoint();
  const globalToolbarContent: GlobalToolbarContent = props.data.shGlobalToolbar;
  const featureToggles = props.data.shSpectrumhealthOrgGlobalSettings.feature_toggles;
  const useGlobalFlatFooter = useFeatureToggle("global-flat-footer", featureToggles);
  const useAllTab = useFeatureToggle("search-all-tab", featureToggles);
  const useLocationTab = useFeatureToggle("search-locations", featureToggles);
  const location = useLocation();
  const [queryText, setQueryText] = useState("");
  const [activeTab, setActiveTab] = useState("all");
  const [activePage, setActivePage] = useState("1");
  const [origin, setOrigin] = useState("");
  const [filter, setFilter] = useState<string[]>([]);
  const [orFilter, setOrFilter] = useState<string[]>([]);
  const [notFilter, setNotFilter] = useState<string[]>([]);
  //const conversationAgentEnabled = useFeatureToggle("clearstep-conversation-agent", featureToggles);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant"
    });
  }, []);

  // if this isnt present the gatsby server side build fails: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  let queryParams = new URLSearchParams();
  const isBrowser = typeof window !== "undefined";
  /* istanbul ignore else */
  if (isBrowser) {
    queryParams = new URLSearchParams(window?.location.search);
  }
  // maintain the search query when a user switches between tabs
  const retainQuery = useFilteredQueryParams(queryParams, ["query", "not_filter", "origin", "or_filter", "filter"], {});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("query");
    setQueryText(searchQuery ? searchQuery : "");
    const currentTab = searchParams.get("tab");
    setActiveTab(currentTab ? currentTab : "all");
    const currentPage = searchParams.get("page");
    setActivePage(currentPage ? currentPage : "1");
    const patientLocation = searchParams.get("origin");
    setOrigin(patientLocation ? patientLocation : "");
    // separate gell all filter params
    const filterValues = searchParams.getAll("filter");
    setFilter(filterValues);
    const orFilterValues = searchParams.getAll("or_filter");
    setOrFilter(orFilterValues);
    const notFilterValues = searchParams.getAll("not_filter");
    setNotFilter(notFilterValues);
  }, [location.search]);

  usePageHasScrolled(pageRef, props.data?.shSpectrumhealthOrgGlobalSettings.feature_toggles);

  /**
   * this hook sets the subsite data when we land on a page
   * */
  useEffect(() => {
    appDataContext.setSubsiteData?.(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setSubsiteData]);

  /**
   * this hook sets the regional links modal data when we land on homepage
   * */
  useEffect(() => {
    appDataContext.setRegionalLinksModalData?.(props.data.shRegionalLinksModal as RegionalLinksModalContent);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appDataContext.setRegionalLinksModalData]);

  /**
   * This effect checks if our menus are open and sets our menusOpen bool
   */
  useEffect(() => {
    setMenusOpen(uiStateContext.menuOpen || uiStateContext.auxNavOpen || uiStateContext.subsiteMenuOpen);
  }, [setMenusOpen, uiStateContext.menuOpen, uiStateContext.auxNavOpen, uiStateContext.subsiteMenuOpen]);

  const handleSetTab = (tab: string) => {
    if (tab === "locations") {
      if (retainQuery === "") {
        navigate(`/search/?tab=${encodeURI(tab)}`);
      } else {
        navigate(`/search/?${retainQuery}&tab=${encodeURI(tab)}`);
      }
    } else if (tab !== "locations" && retainQuery === "") {
      navigate(`/search/`);
    } else {
      navigate(`/search/?${retainQuery}`);
    }
  };

  const seo = props.data?.shSearchPage && props.data?.shSearchPage?.seo && (
    <SEO
      siteName={props.data.shSpectrumhealthOrgGlobalSettings.title}
      // TODO could switch site url based on active ENV
      siteUrl={props.data.shSpectrumhealthOrgGlobalSettings.prod_url}
      title={props.data.shSearchPage.seo.title || props.data.shSearchPage.title}
      facebookDomainVerification={props.data.shSpectrumhealthOrgGlobalSettings.facebook_domain_verification}
      twitterCreator={props.data.shSpectrumhealthOrgGlobalSettings.twitter_creator}
      twitterSite={props.data.shSpectrumhealthOrgGlobalSettings.twitter_site}
      description={props.data.shSearchPage.seo.description || props.data.shSpectrumhealthOrgGlobalSettings.title}
      defaultSiteImage={props?.data?.shSpectrumhealthOrgPrimaryHeader?.logo?.url}
      pagePath="/"
      keywords={props.data.shSearchPage.seo.keywords || ""}
      schema={`{
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "${siteName}",
                "image": "${props.data.shSearchPage.seo.image?.url}"
              }
            }`}
    />
  );

  const embedScripts = appDataContext.embeddedScripts ? (
    <EmbeddedScript embedScriptBlocks={appDataContext.embeddedScripts} featureToggles={featureToggles} pagePath={"/"} />
  ) : null;

  const allTabRender = useAllTab ? <div className="all-tab-content">
    {props.data.shSearchPage?.all_tab && <SearchTab content={props.data.shSearchPage.all_tab} corewellStyleOverrides={props.corewellStyleOverrides} featureToggles={featureToggles} iconPack={staticIcons} />}
  </div> : null;

  const locationTabRender = useLocationTab ? <div className="location-tab-content">
    {props.data.shSearchPage?.location_tab && <SearchTab content={props.data.shSearchPage.location_tab} corewellStyleOverrides={props.corewellStyleOverrides} featureToggles={featureToggles} iconPack={staticIcons} />}
  </div> : null;

  return (
    <>
      <StickyHeader
        featureToggles={featureToggles}
        globalNavContent={globalNavContent}
        primaryHeaderContent={props?.data?.shSpectrumhealthOrgPrimaryHeader}
        staticIcons={staticIcons}
        campaignPageActive={false}
        title={props?.data?.shSpectrumhealthOrgGlobalSettings?.title}
        globalToolbarContent={globalToolbarContent}
        corewellStyleOverrides={props.corewellStyleOverrides}
      />
      {/* {conversationAgentEnabled && props.clearStepWidget && props.clearStepResizeScript && (
        <>
          <Helmet>
            <script type="text/javascript" src={props.clearStepResizeScript}></script>
          </Helmet>
          <iframe id="widget-iframe" src={props.clearStepWidget} title="Clearstep Symptom Check Widget" style={{ width: 0, height: 0 }}></iframe>
        </>
      )} */}
      {/* todo: remove the hidden below when we can fix the modal/menu overflow scroll issue, it would be better not to have this */}
      <div hidden={deviceType < DeviceType.Tablet && menusOpen}>
        {seo}
        {embedScripts}
        <div ref={pageRef} className="page" id="page-content">
          <div className="global-search page" id="page-content">
            <SearchBox content={props.data?.shSearchPage?.search_box?.[0]} iconPack={staticIcons} queryText={queryText} />
            <Box className="tabs" marginX={'auto'} px={layout.pagePaddingX} width={layout.pageWidth}>
              <div className="tab-names">
                {useAllTab &&
                  <button className={`all-tab-title tab-btn-unstyled ${activeTab === "all" && "active"}`} onClick={() => handleSetTab("all")} data-testid="all-tab-content-test-id">{props.data.shSearchPage?.all_tab[0]?.search_tab_display_name}</button>
                }
                {useLocationTab &&
                  <button className={`location-tab-title tab-btn-unstyled ${activeTab === "locations" && "active"}`} onClick={() => handleSetTab("locations")} data-testid="location-tab-content-test-id">{props.data.shSearchPage?.location_tab[0]?.search_tab_display_name}</button>
                }
              </div>
            </Box>
            {/* if there isnt a query render the correct empty state tab content */}
            {queryText === "" ? (activeTab === "all" ? allTabRender : locationTabRender) : null}

            {/* If there is a query display the results component */}
            {queryText != "" ? (
              <DynamicSearchResults
                featureToggles={featureToggles}
                iconPack={staticIcons}
                globalNavContent={globalNavContent}
                noResultEntryUid="blt7994a7d481380f19"
                filterContent={filterContent}
                queryText={queryText}
                activeTab={activeTab}
                activePage={activePage}
                origin={origin}
                filter={filter}
                orFilter={orFilter}
                notFilter={notFilter}
              />
            ) : null}
          </div>
        </div>
        {useGlobalFlatFooter ? (
          <GlobalFlatFooter
            content={props.data?.shGlobalFlatFooter as GlobalFlatFooterContent}
            copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
            iconPack={staticIcons}
            title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
          />
        ) : (
          <GlobalFooter
            iconPack={staticIcons}
            content={props.data?.shSpectrumhealthOrgGlobalFooterNav as GlobalFooterContent}
            copyrightSubtext={props.data?.shSpectrumhealthOrgGlobalSettings?.copyright_subtext}
            title={props.data?.shSpectrumhealthOrgGlobalSettings.title}
          />
        )}
      </div>
    </>
  );
};

export default GlobalSearchPage;
