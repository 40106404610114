import { useLayoutEffect, useState, useCallback, Dispatch, useEffect } from "react";

import KendraApiService, { Location, Page } from "../../services/kendra-api/kendra-api-service";

// eslint-disable-next-line
export type KendraApiResponse = {
  query_result: Array<Location | Page>;
  query_suggestions: Array<string>;
  total_results?: number;
};

export type UseKendraSearchResultsProps = {
  query_text: string;
  page_num: string;
  page_size: string;
  location_results: boolean;
};

export type ApiServiceStatus = {
  response: KendraApiResponse;
  isRunning: boolean;
  hasError: boolean;
  error?: any;
};

const useKendraSearchResults = (kendraProps: UseKendraSearchResultsProps): [ApiServiceStatus, Dispatch<React.SetStateAction<UseKendraSearchResultsProps>>] => {
  const [kendraQuery, setkendraQuery] = useState(kendraProps);
  const [isRunning, setIsRunning] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState(undefined as any);
  const [response, setResponse] = useState({} as KendraApiResponse);
  const [apiStatus, setApiStatus] = useState({ isRunning: false, response: response } as ApiServiceStatus);

  useLayoutEffect(() => {
    setApiStatus({ isRunning: isRunning, hasError: hasError, response: response, error: error });
  }, [isRunning, hasError, response, error]);

  const apiCall = useCallback(async () => {
    if(kendraQuery.query_text.length < 3){
      setIsRunning(false);
      return;
    }
    setIsRunning(true);
    setHasError(false);
    try {
      const content = await KendraApiService.getKendraSearchResults(kendraQuery.query_text, kendraQuery.page_num, kendraQuery.page_size, kendraQuery.location_results);
      setHasError(false);
      setIsRunning(false);
      setResponse(content as KendraApiResponse);
    } catch (err) {
      setResponse({} as KendraApiResponse);
      setError(err);
      setHasError(true);
      setIsRunning(false);
    }
  }, [kendraQuery.query_text, kendraQuery.page_num, kendraQuery.location_results]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);

  return [apiStatus, setkendraQuery];
};

export default useKendraSearchResults;
