import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";

import Checkbox from "../../shared/components/checkbox/checkbox";
import ShIcon from "../../shared/components/sh-icon/sh-icon";
import useFilteredQueryParams from "../../shared/hooks/filter-params/useFilteredQueryParams";
import { DeviceType } from "../../shared/hooks/use-breakpoint/device-type";
import useBreakpoint from "../../shared/hooks/use-breakpoint/useBreakpoint";
import { IconPack, SearchFilterContent } from "../../shared/models/app-data-model";
import "./search-filter.scss";

export type SearchFilterProps = {
    content: SearchFilterContent;
    iconPack: IconPack[];
};

const SearchFilter: React.FC<SearchFilterProps> = ({ content, iconPack }: SearchFilterProps) => {
    const [zipcode, setZipCode] = useState("");
    const [validZipcode, setValidZipcode] = useState(true);
    const [selectedIds, setselectedIds] = useState<string[]>([]);
    const [applyBtn, setApplyBtn] = useState(false);
    let retainTabLocation = "";
    let restorePreviousSearch = "";
    const deviceType = useBreakpoint();

    // if this isnt present the gatsby server side build fails: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
    let queryParams = new URLSearchParams();
    const isBrowser = typeof window !== "undefined";
    /* istanbul ignore else */
    if (isBrowser) {
        queryParams = new URLSearchParams(window?.location.search);
    }
    

    useEffect(() => {
        const getOrigin = queryParams.get("origin") || "";
        const existingFilters = queryParams.getAll("filter");
        const existingOrFilters = queryParams.getAll("or_filter");
        const existingNotFilters = queryParams.getAll("not_filter");

        // less than 0.01ms difference between spread and concat() when handling small arrays
        const allExistingFilters = [...existingFilters, ...existingOrFilters, ...existingNotFilters];

        allExistingFilters.map((value) => {
            const selectedFilter = content?.tab_filter[0]?.filter_values?.find((elem) => elem.filter_contents === value.replace(/ /g, "+"));
            setselectedIds((prev) => [...prev, selectedFilter?.filter_display_name.replace(/ /g, "+") || ""]);
        });

        setZipCode(getOrigin);
        if (getOrigin !== "") {
            setselectedIds((prev) => [...prev, "ch-search-zipcode"]);
            setValidZipcode(true);
        }
    }, []);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApplyBtn(true);
        const checkedId = event.currentTarget.id;
        if (event.target.checked) {
            setselectedIds([...selectedIds, checkedId]);
        } else {
            setselectedIds(selectedIds.filter((id) => id !== checkedId));
        }
    };

    const handleZipCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setZipCode(event.target.value);
        setApplyBtn(true);
        
        if (event.target.value.length > 0) {
            if (!selectedIds.includes(event.target.id)) {
                setselectedIds([...selectedIds, event.target.id]);
            }
        } else {
            setselectedIds(selectedIds.filter((id) => id !== event.currentTarget.id));
        }
    };

    const mobileClearInput = () => {
        setZipCode("");
        setApplyBtn(true);
    };

    const clearAllFilters = () => {
        setselectedIds([]);
        setZipCode("");
        navigate(`/search/?${restorePreviousSearch}`);
    };
    restorePreviousSearch = useFilteredQueryParams(queryParams, ["tab", "query"], {});

    const validateZipCode = () => {
        var regex = /^[0-9]{5}$/;

        if (regex.test(zipcode)) {
            setValidZipcode(true);
            return true;
        } else {
            setValidZipcode(false);
            return false;
        }
    };

    const validateForm = (event: React.FormEvent<HTMLFormElement>) => {
        let filterQueryStr = "";
        event.preventDefault();

        selectedIds.map((item) => {
            if (item === "ch-search-zipcode" && zipcode !== "") {
                filterQueryStr += "&origin=" + zipcode;
            } else {
                const checkboxObj = content?.tab_filter[0]?.filter_values?.find((elem) => elem.filter_display_name.replace(/ /g, "+") === item);
                filterQueryStr += checkboxObj?.filter_type ? "&" + checkboxObj?.filter_type + "=" + checkboxObj?.filter_contents : "";
            }
        });

        const validateZipValue = validateZipCode();
        if (!validateZipValue && zipcode !== "") {
            return;
        }
        if (applyBtn || (zipcode && validateZipValue)) {
            navigate(`/search/?${(retainTabLocation)}${filterQueryStr}`);
        }
    };

    // if the user is on a search tab maintain that with their new search
    retainTabLocation = useFilteredQueryParams(queryParams, ["query", "tab"], {});

    const checkboxList = content?.tab_filter?.[0].filter_values.map((item) => (
        <Checkbox
            uniqueId={item.filter_display_name.replace(/ /g, "+")}
            label={item.filter_display_name}
            checked={selectedIds.includes(item.filter_display_name.replace(/ /g, "+"))}
            onChangeFunction={(event) => {
                handleCheckboxChange(event);
            }}
            data-testid={item.filter_display_name}
        />
    ));

    return (
        <Flex className="ch-search-filter" data-testid="ch-search-filter" width={1}>
            <form className="ch-search-filter-form" data-testid="ch-search-filter-form" onSubmit={(event) => validateForm(event)}>
                <div className="ch-search-filter-input-container">
                    <div className={`ch-search-zipcode-wrapper ${zipcode !== "" && !validZipcode && "zip-err-border"}`}>
                        <input
                            className="ch-search-zipcode"
                            type="text"
                            onChange={(event) => handleZipCodeChange(event)}
                            value={zipcode}
                            placeholder={zipcode !== "" ? zipcode : "Zip Code"}
                            id="ch-search-zipcode"
                            data-testid="ch-search-zipcode"
                            maxLength={15}
                        />
                        {zipcode !== "" && deviceType <= DeviceType.Tablet && (
                            <button className="ch-clear-zip-button" onClick={() => mobileClearInput()} data-testid="x-icon" type="button">
                                <ShIcon iconPack={iconPack} iconName="close" className="icon ch-search-x-icon" />
                            </button>
                        )}
                    </div>
                    <span className={validZipcode || zipcode === "" ? "ch-err-disable" : "ch-err-enable"}>
                        Please enter a valid U.S. zip code
                    </span>
                    <div className="ch-search-checkbox">{checkboxList}</div>
                    {selectedIds.length > 0 ? (
                        <div className="ch-search-clear-filter">
                            <button
                                className="unstyled-btn ch-clear-filters-btn"
                                data-testid="ch-clearFilters-btn"
                                type="button"
                                onClick={() => clearAllFilters()}
                            >
                                Clear all filters
                            </button>
                        </div>
                    ) : null}
                </div>
                <button
                    className={applyBtn ? "ch-search-icon-container-enable" : "ch-search-icon-container-disable"}
                    data-testid="ch-search-applyBtn"
                    type="submit"
                    disabled={!applyBtn}
                >
                    Apply
                </button>
            </form>
        </Flex>
    );
};

export default SearchFilter;
