import React from "react";
import './search-tab.scss';
import { SearchTabContent, IconPack, FeatureToggle } from "../../shared/models/app-data-model";
import PageModularBlock from "../../page/page-modular-block/page-modular-block";
import SearchPills from "../search-pills/search-pills";
import { Box } from "rebass/styled-components";
import { layout } from "../../constants/constants";

export type SearchTabProps = {
    content: SearchTabContent[];
    corewellStyleOverrides?: boolean;
    featureToggles: FeatureToggle[];
    iconPack: IconPack[];
}

const SearchTab: React.FC<SearchTabProps> = ({ content, corewellStyleOverrides, featureToggles, iconPack }: SearchTabProps) => {

    const tabModularBlocks = content[0].modular_blocks && content[0].modular_blocks.map((item, index) => <PageModularBlock
        iconPack={iconPack}
        blockContent={item}
        key={index}
        featureToggles={featureToggles}
        corewellStyleOverrides={corewellStyleOverrides}
    />
    );

    return (
        <>
            {content[0] && (
                <div className="search-tab">
                    <div className="search-tab-container">
                        <Box className="search-pills-container" width={layout.pageWidth} px={layout.pagePaddingX} marginX={'auto'}>
                            <SearchPills section_title={content[0].search_pills[0].section_title} pills={content[0].search_pills[0].pills} />
                        </Box>
                        {tabModularBlocks}
                    </div>
                </div>
            )}
        </>
    )
}

export default SearchTab;