import React from "react";
import './search-pills.scss';
import { SearchPillsContent } from "../../shared/models/app-data-model";
import SearchPill from "../search-pill/search-pill";

export type SearchPillsProps = {
    search_pills: SearchPillsContent[];
}

const SearchPills: React.FC<SearchPillsContent> = ({section_title, pills}: SearchPillsContent) => {

    return (
        <div className="search-pills">
            <div className="search-pills-section-title">{section_title}</div>
            <div className="search-pill-container">
            {pills.map((pill, index) => (
                <SearchPill key={'search-pill-' + index} content={pill} />
            ))}
            </div>
        </div>
    )
}

export default SearchPills;