import React, { useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";

import { IconPack, CorewellSearchBarContent } from "../../shared/models/app-data-model";
import ShIcon from "../../shared/components/sh-icon/sh-icon";
import "./corewell-search-bar.scss";
import { navigate } from "gatsby";
import useFilteredQueryParams from "../../shared/hooks/filter-params/useFilteredQueryParams";

export type CorewellSearchBarProps = {
  content: CorewellSearchBarContent;
  iconPack: IconPack[];
  queryText: string;
};

const CorewellSearchBar: React.FC<CorewellSearchBarProps> = ({ content, iconPack, queryText }: CorewellSearchBarProps) => {
  const [searchTerm, setSearchTerm] = useState(queryText); 
  const [showXIcon, setShowXIcon] = useState(false);

  // if this isnt present the gatsby server side build fails: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  let queryParams = new URLSearchParams();
  const isBrowser = typeof window !== "undefined";
  /* istanbul ignore else */
  if (isBrowser) {
    queryParams = new URLSearchParams(window?.location.search);
  }
  // if the user is on a search tab maintain that with their new search
  const retainTabLocation = useFilteredQueryParams(queryParams, ['tab'], {query: searchTerm});

  const handleXIcon = (event: any) => {
    event.preventDefault();
    setSearchTerm("");
  };

  useEffect(() => {
    // updates the search term if it exists in the query string parameter
    setSearchTerm(decodeURIComponent(queryText));
  }, [queryText]);

  useEffect(() => {
    if (searchTerm.length) {
      setShowXIcon(true);
    } else {
      setShowXIcon(false);
    }
  }, [searchTerm]);

  const handleSearch = () => navigate(`/search/?${retainTabLocation}`);

  const handleSearchButtonClick = (event: any) => {
    event.preventDefault();
    handleSearch();
  };

  const handleKeyDown = (event: any) => {
    const isEnterKey = event.code === 'Enter' || event.key === 'Enter';
    
    if (isEnterKey) {
      handleSearchButtonClick(event);
    }
  };

  return (
    <Flex className="ch-search-bar" data-testid="ch-search-bar">
      <form className="ch-search-form" onSubmit={handleSearchButtonClick} data-testid="ch-search-form">
        <div className="ch-search-input-container">
          <label htmlFor="ch-search" className="visually-hidden">Search</label>
          <input
            className="ch-search-terms"
            type="text"
            onChange={(event) => setSearchTerm(event.target.value)}
            onKeyDown={handleKeyDown}
            value={searchTerm}
            placeholder={content?.search_input_placeholder}
            id="ch-search"
            data-testid="ch-search-terms"
            autoComplete="off"
          />
          {showXIcon && (
            <button className="ch-clear-search-button" onClick={handleXIcon} data-testid="x-icon">
              <ShIcon iconPack={iconPack} iconName="close" className="icon ch-search-x-icon" />
            </button>
          )}
        </div>
        <button className="ch-search-icon-container" type="submit">
          <ShIcon iconPack={iconPack} iconName="search" className="icon ch-search-icon" />
        </button>
      </form>
    </Flex>
  );
};

export default CorewellSearchBar;
