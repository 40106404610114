import httpClient from "../http-client/http-client";

export type KyruusApiProps = {
    unified: string;
    origin?: string | null;
    distance?: string | null;
    page?: string | null;
    per_page?: string | null;
    filter?: string[] | null;
    orFilter?: string[] | null;
    notFilter?: string[] | null;
};

export type KyruusLocationData = {
    title?: string;
    attributes?: {
        location_id?: string;
        location_name?: string;
        street_address?: string;
        street_address_2?: string;
        city?: string;
        zip_code?: string;
        state?: string;
        latitude: number;
        longitude?: number;
        distance?: number;
        location_specialty?: string[];
        location_services?: string[];
        _category?: string;
        location_pic?: string;
        _source_uri?: string;
    }
}

export type DataResponse = {
    data?: KyruusApiResponse;
}

export type KyruusApiResponse = {
    total_locations?: number;
    location_origin_info?: {
        address?: {
            city?: string;
            county?: string;
            fips?: string;
            plus4?: string;
            state?: string;
            street?: string;
            street2?: string;
            suite?: string;
            type?: string;
            zip?: string;
        },
        coordinates?: {
            latitude?: number;
            longitude?: number;
        },
    },
    location_metadata?: KyruusLocationData[];
}

const KyruusApiService = {
    getKyruusResults(kyruusProps: KyruusApiProps) {
        const params = new URLSearchParams();

        if(kyruusProps.unified) {
            params.append('unified', kyruusProps.unified);
        }
        if(kyruusProps.origin) {
            params.append('origin', kyruusProps.origin);
        }
        if(kyruusProps.distance) {
            params.append('distance', kyruusProps.distance);
        }
        if(kyruusProps.page) {
            params.append('page', kyruusProps.page);
        }
        if(kyruusProps.per_page) {
            params.append('per_page', kyruusProps.per_page);
        }
        if(kyruusProps.filter) {
            kyruusProps.filter.forEach(value => params.append('filter', value));
        }
        if(kyruusProps.orFilter) {
            kyruusProps.orFilter.forEach(value => params.append('_filter', value));
        }
        if(kyruusProps.notFilter) {
            kyruusProps.notFilter.forEach(value => params.append('-filter', value));
        }
        
        let queryParams = params.toString();

        const baseUrl = process.env.GATSBY_KYRUUS_API_ENDPOINT;
        const path = `${baseUrl}/kyruus/{proxy+}?${queryParams}`;
        const key = process.env.GATSBY_KYRUUS_APIKEY;
        const headers = { "x-api-key": key, "Content-Type": "application/json" };

        if (queryParams != "" && baseUrl != undefined && key != undefined) {
            return httpClient
                .get(path, headers)
                .then((response: DataResponse) => {
                    return response.data;
                })
                .catch((e) => {
                    throw new Error(`Error when calling Kyruus API: ${e}`);
                });
        }
              
        return [];    
    }
};

export default KyruusApiService;