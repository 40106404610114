import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Flex, Box } from 'rebass/styled-components';
import { DeviceType } from '../../../shared/hooks/use-breakpoint/device-type';
import useBreakpoint from '../../../shared/hooks/use-breakpoint/useBreakpoint';
import { FeatureToggle, GlobalSearchNoResultsContent, IconPack, SearchFilterContent } from '../../../shared/models/app-data-model';
import { Location } from '../../../shared/services/kendra-api/kendra-api-service';
import NoResults from '../../no-results/no-results';
import Paginator from '../../paginator/paginator';
import SearchTotalResults from '../../search-total-results/search-total-results';
import LocationCardDesktop from '../location-card-desktop/location-card-desktop';
import LocationCardMobile from '../location-card-mobile/location-card-mobile';
import { KyruusLocationData } from "../../../shared/services/kyruus-api/kyruus-api-service";
import './kyruus-locations.scss';
import useFilteredQueryParams from '../../../shared/hooks/filter-params/useFilteredQueryParams';
import { navigate } from 'gatsby';
import SearchFilter from "../../search-filter/search-filter";
import ShIcon from '../../../shared/components/sh-icon/sh-icon';

export type LocationSearchResultProps = {
  locations: KyruusLocationData[];
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  searchSuggestions?: string[];
  noResultsFoundContent?: GlobalSearchNoResultsContent;
  totalResults: number;
  searchTerm?: string;
  activePage: number;
  kyruusOriginCity?: string;
  filterContent: SearchFilterContent;
};

const Kyruuslocations: React.FC<LocationSearchResultProps> = ({
  locations,
  iconPack,
  featureToggles,
  searchSuggestions,
  noResultsFoundContent,
  totalResults,
  searchTerm,
  activePage,
  kyruusOriginCity,
  filterContent
}: LocationSearchResultProps) => {
  // format the kyruus data 
  let formattedLocationData = [];
  const formatKyruusData = (searchResults) => searchResults.map(({ Attributes }) => ({
    location_id: Attributes?.location_id,
    location_name: Attributes?.location_name,
    street_address: `${Attributes?.street_address}${Attributes?.street_address_2 ? " " + Attributes?.street_address_2 : ""}`,
    city: Attributes?.city,
    state: Attributes?.state,
    _source_uri: Attributes?._source_uri,
    _category: Attributes?._category,
    zip_code: Attributes?.zip_code,
    distance: Attributes?.distance?.toFixed(2),
    latitude: Attributes?.latitude,
    longitude: Attributes?.longitude,
    location_specialty: Attributes?.location_specialty,
  }));
  if (locations?.length > 0) {
    formattedLocationData = formatKyruusData(locations);
  }

  // if this isnt present the gatsby server side build fails: https://www.gatsbyjs.com/docs/debugging-html-builds/#how-to-check-if-window-is-defined
  let queryParams = new URLSearchParams();
  const isBrowser = typeof window !== "undefined";
  /* istanbul ignore else */
  if (isBrowser) {
    queryParams = new URLSearchParams(window?.location.search);
  }

  const deviceType = useBreakpoint();
  const [currentPage, setCurrentPage] = useState(activePage);
  const [totalPages] = useState(Math.ceil(totalResults / 10));
  const paramsFilter = ["query", "tab", "per_page", "origin", "distance", "filter", "or_filter", "not_filter"];
  const retainParams = useFilteredQueryParams(queryParams, paramsFilter, {});
  const paginationDetails = { currentPage: currentPage, totalPages: totalPages };
  const [searchOverlayOpen, setSearchOverlayOpen] = useState(false);
  const [existingFilters, setExistingFilters] = useState(false);

  const onPageChange = useCallback(
    (currentPage: number): void => {
      setCurrentPage(currentPage);
      navigate(`/search/?${retainParams}&page=${encodeURI(currentPage.toString())}`);
      window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },
    [setCurrentPage]
  );

  useEffect(() => {
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [deviceType]);

  useEffect(() => {
    const orfilters = queryParams.getAll("or_filter");
    const originFilters = queryParams.get("origin");
    if (orfilters.length > 0 || originFilters !== null) {
      setExistingFilters(true);
    }
  }, []);

  const restorePreviousSearch = useFilteredQueryParams(queryParams, ['tab', 'query'], {});
  const clearAllFilters = () => {
    navigate(`/search/?${restorePreviousSearch}`);
  };

  const locationsList = (
    <Flex flexDirection="column">
      {(formattedLocationData as Location[])?.map((item, index) => (
        <Box key={item.location_id}>
          {deviceType === DeviceType.Desktop ? (
            <LocationCardDesktop
              iconPack={iconPack}
              key={`location-card-desktop-${item.location_id}`}
              location={item as Location}
              elementId={`results-card-${index}`}
              isKyruusData={true}
              kyruusOriginCity={kyruusOriginCity}
              kyruusTabCard={true}
            />
          ) : (
            <LocationCardMobile
              iconPack={iconPack}
              key={`location-card-mobile-${item.location_id}`}
              location={item as Location}
              elementId={`results-card-${index}`}
              isKyruusData={true}
              kyruusOriginCity={kyruusOriginCity}
            />
          )}
        </Box>
      ))}
    </Flex>
  );

  const desktopView = (
    <Box className='search-results-desktop-wrapper' marginTop={2} width={1}>
      <SearchTotalResults count={totalResults} searchTerm={searchTerm} dynamicSearch={true} />
      <Flex className='search-list-filter-container' flexDirection="row" justifyContent="space-between">
        {locations?.length > 0 ?
          <>
            <Flex className='search-results-list-wrapper' flexDirection="column">
              {locationsList}
              <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} iconPack={iconPack} dynamicSearch={true} />
            </Flex>
          </>
          :
          <Flex className="no-results-desktop-wrapper">
            <NoResults
              content={noResultsFoundContent}
              iconPack={iconPack}
              searchSuggestions={searchSuggestions}
              featureToggles={featureToggles}
              kyruusNoResults={true}
              searchTerm={searchTerm}
            />
          </Flex>
        }
        <Flex className="search-filters-wrapper" flexDirection="column">
          <Flex className="search-filter-option" >
            <SearchFilter content={filterContent} iconPack={iconPack} />
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );

  const mobileView = (
    <>
      <Flex flexDirection="column" width={1} className="mobile-view-container results-container">
        <Box>
          <Flex className='search-filter-toggle-container' alignItems="center" height="45px" px={3}>
            <button className='unstyled-btn search-filter-toggle-btn' onClick={() => setSearchOverlayOpen(!searchOverlayOpen)}>
              <Flex><ShIcon iconPack={iconPack} iconName="bold_caret_down" className={searchOverlayOpen ? "icon bold-caret-up-icon" : "icon bold-caret-down-icon"} /></Flex>
              <div className='search-toggle-name' data-testid="search-toggle-name-test">{searchOverlayOpen ? "Back to results" : "Refine your search"}</div>
            </button>
            {existingFilters === true && !searchOverlayOpen ?
              <div className="ch-search-clear-filter">
                <button className="unstyled-btn ch-kyuus-clear-filters-btn" data-testid="ch-clearFilters-btn" type="button" onClick={() => clearAllFilters()}>Clear all filters</button>
                <Flex><ShIcon iconPack={iconPack} iconName="blue_close" className={"icon blue-close-icon"} /></Flex>
              </div>
              :
              null
            }
          </Flex>

          {searchOverlayOpen ?
            <Flex className="search-filters-wrapper" flexDirection="column" width={1}>
              <Flex className="search-filter-option" width={1}>
                <SearchFilter content={filterContent} iconPack={iconPack} />
              </Flex>
            </Flex>
            :
            formattedLocationData?.length > 0 ?
              <Box className='search-results-mobile-container'>
                <SearchTotalResults count={totalResults} searchTerm={searchTerm} dynamicSearch={true} />
                {locationsList}
                <Paginator paginationDetails={paginationDetails} onPageChange={onPageChange} iconPack={iconPack} dynamicSearch={true} />
              </Box>
              :
              <Box className='search-results-mobile-container'>
                <SearchTotalResults count={totalResults} searchTerm={searchTerm} dynamicSearch={true} />
                <NoResults
                  content={noResultsFoundContent}
                  iconPack={iconPack}
                  searchSuggestions={searchSuggestions}
                  featureToggles={featureToggles}
                  kyruusNoResults={true}
                  searchTerm={searchTerm}
                />
              </Box>
          }
        </Box>
      </Flex>
    </>
  );

  return <>{deviceType === DeviceType.Desktop ? desktopView : mobileView}</>;
};

export default Kyruuslocations;
