import React, { useState, useEffect } from "react";
import { Flex } from "rebass/styled-components";

import { layout } from "../../constants/constants";
import ErrorBanner from "../../shared/components/error-banner/error-banner";
import LoadingSpinner from "../../shared/components/loading-spinner/loading-spinner";
import SkeletonState from "../../shared/components/skeleton-state/skeleton-state";
import useContentStack from "../../shared/hooks/contentstack-api/useContentStack";
import useKendraSearchResults from "../../shared/hooks/kendra-api/useKendraSearchResults";
import useFeatureToggle from "../../shared/hooks/use-feature-toggle/useFeatureToggle";
import useKyruusApi from '../../shared/hooks/kyruus-api/useKyruusApi';
import { KyruusApiResponse, KyruusLocationData } from "../../shared/services/kyruus-api/kyruus-api-service";
import { FeatureToggle, GlobalNavContent, GlobalSearchNoResultsContent, IconPack, SearchFilterContent } from "../../shared/models/app-data-model";
import AllResults from "../all-results/all-results";
import Kyruuslocations from "../locations/kyruus-locations/kyruus-locations";
import NoResults from "../no-results/no-results";
import "./dynamic-search-results.scss";

export type DynamicSearchResultsProps = {
  featureToggles: FeatureToggle[];
  iconPack: IconPack[];
  globalNavContent: GlobalNavContent;
  noResultEntryUid: string;
  queryText: string;
  activeTab: string;
  activePage: string;
  filterContent: SearchFilterContent;
  origin: string;
  filter: string[];
  orFilter: string[];
  notFilter: string[];
};

const DynamicSearchResults: React.FC<DynamicSearchResultsProps> = ({
  featureToggles,
  iconPack,
  globalNavContent,
  noResultEntryUid,
  queryText,
  activeTab,
  activePage,
  filterContent,
  origin,
  filter,
  orFilter,
  notFilter,
}: DynamicSearchResultsProps) => {
  const useSearchSkeletonStateLoader = useFeatureToggle("search-skeleton-state-loader", featureToggles);
  const showLocations = useFeatureToggle('search-locations', featureToggles);

  let queryParams = new URLSearchParams();
  // if this isnt present the gatsby server side build fails
  const isBrowser = typeof window !== "undefined";
  /* istanbul ignore else */
  if (isBrowser) {
    queryParams = new URLSearchParams(window?.location.search);
  }

  let pageSize = queryParams.get("page_size") || "10";
  const [totalResults, setTotalResults] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [isRunning, setisRunning] = useState(false);
  const [kyruusApiCall, setKyruusQuery] = useKyruusApi({
    unified: queryText,
    page: activePage,
    per_page: pageSize,
    origin: origin,
    filter: filter,
    orFilter: orFilter,
    notFilter: notFilter
  });
  const [kendraApiResponse, setkendraQuery] = useKendraSearchResults({
    query_text: queryText,
    page_num: activePage,
    page_size: pageSize,
    location_results: showLocations
  });
  const kendraSearchResults = kendraApiResponse?.response?.query_result || [];
  const kyruusSearchResponse = kyruusApiCall?.response as KyruusApiResponse;
  const kyruusSearchResults = kyruusSearchResponse?.location_metadata as KyruusLocationData;
  const kyruusOriginCity = kyruusSearchResponse?.location_origin_info?.address?.city;
  const kyruusError = kyruusApiCall?.hasError || false;
  const kendraError = kendraApiResponse?.hasError || false;
  const kyruusRunning = kyruusApiCall?.isRunning || false;
  const kendraRunning = kendraApiResponse?.isRunning || false;
  const kendraResultsResponse = kendraApiResponse?.response?.total_results || 0;
  const kendraTotalResults = kendraResultsResponse > 100 ? 100 : kendraResultsResponse;
  const kyruusTotalResults = kyruusSearchResponse?.total_locations || 0;

  const noResultsFoundContent = useContentStack([
    {
      contentType: "global_search_no_results_spectrumhealth_org",
      entryUid: noResultEntryUid,
      referencedFields: [
        "modular_blocks.content_card_grid.cards.content_card.reference",
        "modular_blocks.content_card_grid.cards.content_card.reference.default_link.reference",
        "modular_blocks.content_card_grid.cards.content_card.reference.southeast_region_link.reference",
        "modular_blocks.content_card_grid.cards.content_card.reference.southwest_region_link.reference",
        "modular_blocks.content_card_grid.cards.content_card.reference.west_region_link.reference",
        "modular_blocks.single_column_content_block.content.button.reference",
        "modular_blocks.single_column_content_block.content.button.reference.default_link.reference",
        "modular_blocks.single_column_content_block.content.button.reference.southeast_region_link.reference",
        "modular_blocks.single_column_content_block.content.button.reference.southwest_region_link.reference",
        "modular_blocks.single_column_content_block.content.button.reference.west_region_link.reference"
      ]
    }
  ])?.responses[0]?.content as GlobalSearchNoResultsContent;

  useEffect(() => {
    if (activeTab == "locations") {

      const apiProps = {
        unified: queryText,
        page: activePage,
        per_page: pageSize,
        origin: origin,
        filter: filter,
        orFilter: orFilter,
        notFilter: notFilter
      }
      setKyruusQuery(apiProps);
      setHasError(kyruusError);
      setisRunning(kyruusRunning);
      setTotalResults(kyruusTotalResults);
    } else {
      const kendraApiProps = {
        query_text: queryText,
        page_num: activePage,
        page_size: pageSize,
        location_results: showLocations
      }
      setkendraQuery(kendraApiProps);
      setHasError(kendraError);
      setisRunning(kendraRunning);
      setTotalResults(kendraTotalResults);
    }
  }, [
    activeTab,
    queryText,
    activePage,
    pageSize,
    origin,
    filter,
    orFilter,
    notFilter,
    showLocations,
    kyruusError,
    kyruusRunning,
    kyruusTotalResults,
    kendraError,
    kendraRunning,
    kendraTotalResults
  ]);

  const searchResultsView = (): any => {
    switch (activeTab) {
      case "locations":
        return (
          <Kyruuslocations
            iconPack={iconPack}
            locations={kyruusSearchResults as KyruusLocationData[]}
            featureToggles={featureToggles}
            noResultsFoundContent={noResultsFoundContent}
            totalResults={totalResults}
            searchTerm={decodeURIComponent(queryText)}
            activePage={parseInt(activePage, 10)}
            kyruusOriginCity={kyruusOriginCity}
            filterContent={filterContent}
          />
        );
      default:
        return (
          <AllResults
            dynamicResults={true}
            iconPack={iconPack}
            results={kendraSearchResults}
            totalResults={kendraTotalResults}
            searchTerm={decodeURIComponent(queryText)}
            featureToggles={featureToggles}
            noResultsFoundContent={noResultsFoundContent}
            activePage={parseInt(activePage, 10)}
            isKyruusData={true}
            kyruusOriginCity={kyruusOriginCity}
          />
        );
    }
  };

  const errorState = (
    <ErrorBanner
      subMessage={
        'An error occurred while trying to connect you to your search results. Please try again later.'
      }
    />
  );

  const noResults = (
    <NoResults
      globalNavContent={globalNavContent}
      content={noResultsFoundContent}
      featureToggles={featureToggles}
      iconPack={iconPack}
    />
  );

  return (
    <Flex
      className="dynamic-search-result"
      justifyContent="space-between"
      width={layout.pageWidth}
      mx="auto"
    >
      <Flex justifyContent={"space-between"} width={1} margin="0">
        {hasError ? (
          errorState
        ) : isRunning ? (
          useSearchSkeletonStateLoader ? (
            <SkeletonState displayFilters={activeTab === "locations"} corewellSkeleton={true} />
          ) : (
            <LoadingSpinner />
          )
        ) : (
          searchResultsView()
        )}
      </Flex>
    </Flex>
  );
};

export default DynamicSearchResults;
