import SearchPage, { SearchPageProps } from "../../../design-system/page/search-page/search-page";
import { graphql } from "gatsby";
import React from "react";

import globalSettings from "../../global-settings.json";

/**
 * This file just creates a static search page
 * and we simply have it load our SearchPage component
 */
const GlobalSearchPage: React.FC<SearchPageProps> = (props: SearchPageProps) => {
  const clearStepWidget = globalSettings?.entry?.clearstep_widget_page_source;
  const clearStepResizeScript = globalSettings?.entry?.clearstep_resize_script_source;

  return (
      <SearchPage
        data={props.data}
        corewellStyleOverrides={true}
        clearStepWidget={clearStepWidget}
        clearStepResizeScript={clearStepResizeScript}
      />
  );
};

export const searchPageQuery = graphql`
  query {
    shGlobalHeaderNav {
      ...globalNavFragment
    }
    shSpectrumhealthOrgPrimaryHeader {
      ...primaryHeaderFragment
    }
    shSpectrumhealthOrgGlobalSettings {
      ...globalSettingsFragment
    }
    shSearchPage {
      ...searchPageFragment
    }
    shSpectrumhealthOrgGlobalFooterNav {
      ...globalFooterFragment
    }
    shGlobalToolbar {
      ...globalToolbarFragment
    }
    shRegionalLinksModal {
      ...regionalLinksModalFragment
    }
    shGlobalFlatFooter {
      ...shGlobalFlatFooterFragment
    }
  }
`;

export default GlobalSearchPage;
